<template>
    <div class="container-fluid mt-4">
    <div class="row">
        <div class="col-sm-12 col-md-10 mx-auto">
            <mdb-card cascade>
                <mdb-view class="card-header-reversed  pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center " cascade>
                    <div class="title-card-tec  card-header-title color-primary-reversed" style="font-weight:400"> Abonnements </div>
                    <mdb-btn
                        v-if="false"
                        class="float-right"
                        tag="a"
                        color="primary"
                        icon="plus"
                        size="sm" 
                        small
                        @click="$router.push({name: 'Creer Abonnement'})"
                    >
                    </mdb-btn>
                </mdb-view>

                <mdb-card-body class="card-reversed" cascade v-loading="loading">
                    <mdb-row>
                        <mdb-col sm="12" md='4' class="mb-n4">
                        <mdb-select flipOnScroll
                             wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed " 
                            outline
                            class=""
                            @getValue="nbrPerPageSelected"
                            v-model="dataPagination.pagination.perPageOptions"
                        />
                        </mdb-col>
                        <mdb-col sm="12" md='4' class="mb-n4">
                        </mdb-col>
                        <mdb-col sm="12" md='4' class="mb-n3">
                        <mdb-input
                             inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                            icon="search"
                            outline
                            type="text"
                            v-model="dataPagination.searchQuery"
                            placeholder="Rechercher"
                            aria-label="Search"
                            />
                        </mdb-col>
                    </mdb-row> 

                    <mdb-tbl responsiveMd >
                        <mdb-tbl-head>
                            <tr class="color-normal-reversed">
                                <th v-for="column in data.columns" :key="column.name" >
                                    {{column.label}}
                                </th>
                            </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                            <tr class="tec-table-row color-normal-reversed "  v-for="row in queriedData" :key="row.name">
                                <td class="tec-table-cell text-align-right" >
                                    <span v-if="row.is_finished === 1" class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span>
                                    <span v-else class="badge rounded-pill bg-warning" style="height:10px; width:10px;display:inline-block"></span>
                                {{ row.name }}</td>
                                <td class="tec-table-cell text-align-right" >{{ row.company.name }}</td>

                                <td class="tec-table-cell text-align-right" >{{ row.start_date }}</td>
                                <td class="tec-table-cell text-align-right" >{{ row.end_date }}</td>
                                <td class="tec-table-cell text-align-right" >{{ row.amount }}</td>
                                <td class="d-flex justify-content-end">
                                    <el-dropdown trigger="click">
                                        <span class="el-dropdown-link" style="cursor:pointer; font-weight:500" >
                                            Actions<i class="el-icon-arrow-down el-icon--right"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                                            <el-dropdown-item @click.native="details(row)" class="dropdown-item-el-tec" icon="el-icon-s-order">Voir les sites</el-dropdown-item>
                                            <el-dropdown-item @click.native="deleteSub(row.id)" class="dropdown-item-el-tec" icon="el-icon-delete">Supprimer</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </td>
                            </tr>
                        </mdb-tbl-body>
                    </mdb-tbl>

                    <div class="d-flex justify-content-end">
                        <tec-pagination
                            v-model="dataPagination.pagination.currentPage"
                            :per-page="parseFloat(dataPagination.pagination.perPage)"
                            :total="dataPagination.pagination.total"
                        ></tec-pagination>
                    </div>
                </mdb-card-body>
            </mdb-card>  
        </div>
    </div>

        <mdb-modal centered  size="md"   :show="modalSubDetails.show" @click="modalSubDetails.show = false">
            <mdb-modal-body class="modal-tec card-reversed px-0">
                <div class="modal-times-tec "  @click="modalSubDetails.show = false"> <i class="fas fa-times"></i> </div>
                <mdb-modal-title style="font-weight:700" class="pl-2">
                    <span v-if="modalSubDetails.content.is_finished === 1" class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span>
                    <span v-else class="badge rounded-pill bg-warning" style="height:10px; width:10px;display:inline-block"></span>
                {{ modalSubDetails.title }}</mdb-modal-title>

                <br>

                <table class="modal-details">
                    <tbody>
                        <tr>
                            <td v-for="site in modalSubDetails.content.sites" :key="site.id" >{{site.name}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end mt-2">
                    <mdb-btn size="md" color="grey" @click="modalSubDetails.show = false">Fermer</mdb-btn>
                </div>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>

<script>
import TecPagination from '@/tba/components/Pagination'
import {
    mdbCard,mdbCardBody,
    mdbView,
    mdbBtn,
    mdbTbl,mdbTblHead,mdbTblBody,
    mdbRow,mdbCol,
    mdbSelect,mdbInput,
     mdbModal,mdbModalTitle,mdbModalBody,
} from 'mdbvue'
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - ABONNEMENTS',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbCard,mdbCardBody,
        mdbView,
        mdbBtn,
        mdbTbl,mdbTblHead,mdbTblBody,
        mdbRow,mdbCol,
        mdbSelect,mdbInput,
        mdbModal,mdbModalTitle,mdbModalBody,
        TecPagination
    },

    data(){
        return {
            dataPagination: {
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"5",value:"5", selected: true},
                        {text:"10",value:"10"},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                loading:false,
                searchQuery: "",
                propsToSearch: [
                    "name",
                    "start_date",
                    "end_date",
                    "amount",
                    "company",
                    "is_finished ",
                ],
            },

            data:{
                columns: [
                    {
                        label: "Abonnement",
                        field: "name",
                        sort: true,
                    },
                    {
                        label: "Entreprise",
                        field: "company_id",
                        sort: true,
                    },
                    {
                        label: "Date Début",
                        field: "start_date",
                        sort: true,
                    },
                    {
                        label: "Date Fin",
                        field: "end_date",
                        sort: true,
                    },
                    {
                        label: "Montant",
                        field: "amount",
                        sort: true,
                    },
                    
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                rows:[]
            },
            modalSubDetails: {
                show: false,
                edit: false,
                title: "Détails de l'abonnement",
                content: {},
            },
        }
    },

    methods:{
        async loadSubscriptions () {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('subscription/findAll')
            .then(response => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.data.rows = response.data.subscriptions
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },
 
        async deleteSub(id){

            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
			await this.$store.dispatch('subscription/delete',id)
                .then((response) => {
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
                    this.loadSubscriptions()
                })
                .catch(error =>{
                    this.$notify({
                           
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
			
		},

        details(row) {
            this.modalSubDetails.content = row

            this.modalSubDetails.title = `${row.company.name}`

            this.modalSubDetails.show = true
            // 
        },

        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
            let result = this.data.rows.filter((row) => {
                let isIncluded = false;

                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";
                    if (typeof row[key] === "object") {
                        switch (key) {
                            case "company":  
                                rowValue = (row[key]) ? (row[key].name).toString().toLowerCase() : '';
                                break;
                            default:
                                break;
                        }
                    }

                    if (typeof row[key] === 'string') {
                        rowValue = row[key].toString().toLowerCase();
                    }
                    if (
                        rowValue.includes &&
                        rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
                        ) { 
                        isIncluded = true;
                        }
                    }
                    return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
           return this.dataPagination.pagination.perPage = nbr
        },
    },
    
    created() {
        this.loadSubscriptions()
    },

    computed: {
        pageData () {
            return this.data.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },
    }
}
</script>